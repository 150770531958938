<script setup>
import AppWrapper from "@/Layouts/AppWrapper.vue";
import {useSlots} from "vue";

const slots = useSlots()
const hasSlot = (name) => {
    return !!slots[name];
}
</script>


<template>
    <AppWrapper>

        <div class="bg-white px-2 pt-8 relative">
            <div
                v-if="hasSlot('header')"
                class="bg-slate-100 absolute top-0 pt-8 pb-4 right-0 left-0 rounded-bl rounded-br shadow">
                <slot name="header"/>
            </div>

            <div :class="[hasSlot('header')? 'mt-20' : 'mt-0']">
                <slot/>
            </div>

            <slot name="ButtonBar"></slot>
        </div>

    </AppWrapper>
</template>
